import React, { useState } from 'react';
import {Tooltip, IconButton} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';

export default function HordeTip({text}) {

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={0}
      title={text}>
      <IconButton onClick={(e) => e.preventDefault()}>
        <HelpIcon viewBox='0 0 28 28' color="secondary" />
      </IconButton>
    </Tooltip>
  );
}
