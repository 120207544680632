import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as api from '../../api/calls';
import { ModioTable } from './ModioTable';

export default function MapsTable() {
    const [data, setData] = useState(['Loading...']);

    useEffect(() => {
        const fetchData = async () => {
            let mapsJSON = [];
            mapsJSON = await api.getMapsModio();
          // console.log(mapsJSON);
            return mapsJSON;
        };
        setData([{ name: 'Loading...' }]);
        fetchData().then(r => setData(r));
    },[]);

    return (
        <Box>
                <p>These maps are available for all horde servers. Use the "UGC" ID below to use the map in RCON</p>

            <ModioTable data={data} setData={setData}></ModioTable>
        </Box>
    );
}