import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MapsTable from '../components/Tables/MapsTable'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography, IconButton } from '@material-ui/core'
import { navigate } from "gatsby"


const MapsPage = ({location}) => {

  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Shack Maps"

  const pageDesc = "List of maps for Pavlov Shack. All maps available on Horde Servers."

  return (
  <Layout pageTitle={pageTitle}>
    <SEO desc={pageDesc} title={pageTitle} />
      <IconButton
      onClick={handleBack}>
        <ArrowBackIcon/>
      </IconButton>
      <Container>
        <Typography variant="h3" style={{ color: "white" }}>
          Maps
          </Typography>
      </Container>

      <MapsTable />
      <Divider />

    </Layout>
  )
}

export default MapsPage
