import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@material-ui/core";
import mapsList from "../../pages/mapsList";
import { SingleSelect } from "../formControls";
import React, { useState } from "react"
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

export default function MapsTable({classes, myMaps, gameModes, handleMapChange, handleModeChange, handleRemove, handleAdd, mapsList, mapsListNames}) {

    return (
        <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table">

                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell>Map ID</TableCell>
                        <TableCell>Game Mode</TableCell>
                        <TableCell>+/-</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>

                    {myMaps.map((map, i) => (

                        <TableRow key={i}>
                            <TableCell>
                                <SingleSelect data={mapsListNames} setValue={(e) => handleMapChange(i, e)} value={map.name} />
                            </TableCell>
                            <TableCell>
                                <SingleSelect data={gameModes} setValue={(e) => handleModeChange(i, e)} value={map.GameMode.replaceAll('"', '')} />
                            </TableCell>
                            <TableCell>
                                <Button size="small" variant="contained" color="primary" onClick={() => handleRemove(i)}><RemoveIcon /></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow key="addrow">
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            <Button size="small" variant="contained" color="secondary" onClick={() => handleAdd()}><AddIcon /></Button>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )
}