import { Box, Typography, TextField, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import { navigate, Link } from "gatsby";
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapSelectTable from './MapSelectTable'

import * as api from '../../api/calls'
import HordeTip from '../HordeTip';

export const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& th": { maxWidth: '20em' },
    },
    table2: {
        // minWidth: 650,
        // "& th": { maxWidth: '20em' },
        "& td": { paddingLeft: '3px', paddingRight: '3px' },
        "& img": { marginBottom: '0px', maxWidth: '10rem' }
    },
    marginAutoItem: {
        margin: 'auto',
        display: 'flex',
    },
    team1: {
        backgroundColor: 'rgba(100,0,0,0.6)'
    },
    team1dead: {
        backgroundColor: 'rgba(100,0,0,0.2)'
    },
    team2: {
        backgroundColor: 'rgba(0,0,100,0.6)'
    },
    team2dead: {
        backgroundColor: 'rgba(0,0,100,0.2)'
    },
    headerRow: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    selectedRow: {
        backgroundColor: 'rgba(0,0,100,0.5)'
    },
    currentMapRow: {
        backgroundColor: 'rgba(0,100,0,0.5)'
    },
    circleButton: {
        borderRadius: '100%'
    },
    errorRow: {
        backgroundColor: 'rgba(100,0,0,0.5)'
    },
    headerOptions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    smallerCellTxt: {
        "& *": {
            fontSize: '0.8rem',
            padding: '0'
        }
    },
    firstCol: {
        maxWidth: '10rem'
    },
    note: {
        // fontFamily: 'Roboto',
        textAlign: 'center'
    },
    mapsNotes: {
        marginBottom: '0'
    },
    href:{
        color: 'white'
    },
    inactive: {
        color: 'grey'
    }

});


function DataRow({ showIP, row, onClick, selected }) {

    const [isHover, setHover] = useState(false)
    const [connectState, setConnect] = useState(false)


    const handleConnect = () => {
        setConnect(!connectState)
        navigate("/rcon", {
            state: {
                server: row
            },
        })
    }

    const handleEnter = () => {
        setHover(true)
    }

    const handleLeave = () => {
        setHover(false)
    }

    return (
        <TableRow
            hover
            key={row.name}
            selected={selected}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            onClick={handleEnter}
        >
            <TableCell component="th" scope="row">{row.name}</TableCell>
            <TableCell align="right">{row.gameMode && `${!!row.bPasswordProtected ? ' 🔒' : ''}${row.slots}`}</TableCell>
            <TableCell align="right">{row.gameMode}</TableCell>
            {isHover ?
                <TableCell align="right"><Button onClick={() => handleConnect()} variant="contained" color="secondary">Connect</Button></TableCell>
                :
                <TableCell align="right">{row.mapLabel}</TableCell>
            }
            {showIP && <TableCell align="right">{row.ip}</TableCell>}

        </TableRow>
    )
}

function ConnectRow({ showIP, row, onCancel }) {

    const [connectState, setConnect] = useState(false)
    const handleConnect = () => {
        setConnect(!connectState)
        navigate("/rcon", {
            state: {
                row
            },
        })
    }

    return (
        <React.Fragment>
            <DataRow showIP={showIP} row={row} onClick={onCancel} selected="true" />
            <TableRow
                hover
                key={row.name}
                selected="true"
            >
                <TableCell align="right">
                    <Link to="/rcon">
                        <Button onClick={() => handleConnect()} variant="contained" color="secondary">{connectState ? 'Connecting...' : 'Connect'}</Button>
                    </Link>
                </TableCell>
                <TableCell align="right"><IconButton onClick={() => onCancel()} aria-label="delete" color="secondary"><CancelIcon /></IconButton></TableCell>
            </TableRow>
        </React.Fragment>
    )
}

function ServerTable({ getData }) {

    const classes = useStyles()
    const [data, setData] = useState(['Loading...'])
    const [refresh, setRefresh] = useState(true)
    const [selectedRow, setSelected] = useState(-1)
    const [showIPs, toggleIPs] = useState(false)
    const [totalPlayers, setTotalPlayers] = useState()
    const [totalServers, setTotalServers] = useState()


    useEffect(() => {
        const fetchData = async () => {
            let servers = await getData();
            servers = servers.sort((a, b) => b.slots - a.slots)
            // console.log(servers)
            setData(servers);
        }
        setData([{ name: 'Loading...' }])
        fetchData();
    }, [refresh]);

    useEffect(() => {
        const thisTotalPlayers = data.reduce((prev, cur) => cur.slots + prev, 0)
        setTotalPlayers(thisTotalPlayers)
        setTotalServers(data.length)
    }, [data]);

    const handleClickRow = (event, name, i) => {
        setSelected(i)
    }

    const handleCancel = () => {
        setSelected(-1)
    }

    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const handleIPCheck = () => {
        toggleIPs(!showIPs)
    }



    return (

        <TableContainer >
            <Box className={classes.headerOptions}>
                {/* <Button
                    variant="contained"
                    onClick={handleRefresh}
                >
                    Refresh
                <RefreshIcon></RefreshIcon>
                </Button> */}
                <Box className={classes.headerOptions}>

                    <Box pr={3} className={classes.note}>{`${totalServers > 1 ? totalServers : '???'} Custom Servers Live with ${totalPlayers || '????'} Players`}</Box>

                    <Checkbox checked={showIPs} onChange={handleIPCheck} /><Typography>Show IPs</Typography>

                </Box>
            </Box>

            <Box pt={1}>
                <Table className={classes.table} size="small" aria-label="a dense table">

                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell className={classes.firstCol}>Name</TableCell>
                            <TableCell align="right">Players</TableCell>
                            <TableCell align="right">GameMode</TableCell>
                            <TableCell align="right">Map</TableCell>
                            {showIPs && <TableCell align="right">IP</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {data.map((row, i) => {
                            const isSelected = selectedRow == i ? true : false
                            const ReturnRow =
                                isSelected ?
                                    <ConnectRow showIP={showIPs} key={row.name + i} row={row} onCancel={handleCancel} /> :
                                    <DataRow showIP={showIPs} key={row.name + i} row={row} onClick={(event) => handleClickRow(event, row, i)} />
                            return ReturnRow
                        })}
                    </TableBody>
                </Table>
            </Box>
        </TableContainer>
    );
}

function ServerTablePC() {

    const classes = useStyles()

    return (
        <Box>
            <Box pb={1}><Button className={`${classes.Button} ${classes.marginAutoItem}`} variant="contained"><Link to="/">Switch to Shack Servers</Link></Button></Box>

            <ServerTable getData={api.getServersPC}></ServerTable>
        </Box>
    )
}

function ServerTableShack({ isRC = false }) {

    const classes = useStyles()

    return (
        <Box>
            <ServerTable getData={api.getServers}></ServerTable>
        </Box>

    )
}

function PlayersTable({ playerList }) {

    const classes = useStyles()

    return (
        <Box pt={1}>
        <TableContainer >

            <Table className={classes.table} size="small">

                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell>Current Players</TableCell>
                        <TableCell >Kills</TableCell>
                        <TableCell >Deaths</TableCell>
                        <TableCell >Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {playerList && playerList.map((player, i) => {
                        const playerName = player.PlayerInfo.PlayerName
                        const playerNameDead = `💀 ${playerName}`
                        const kda = player.PlayerInfo.KDA.split('/')
                        const team = player.PlayerInfo.TeamId
                        const isDead = player.PlayerInfo.Dead
                        let rowClass = (team == "1" ? (isDead ? classes.team1dead : classes.team1) : (isDead ? classes.team2dead : classes.team2))
                        return (
                            <TableRow className={rowClass} key={playerName + i}>
                                <TableCell>
                                    {isDead ? playerNameDead : playerName}
                                </TableCell>
                                <TableCell>
                                    {kda[0]}
                                </TableCell>
                                <TableCell>
                                    {kda[1]}
                                </TableCell>
                                <TableCell>
                                    {kda[2]}
                                </TableCell>
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        </Box>
    )

}

function LoadingServerRow({ row, i }) {
    const classes = useStyles()

    return (
        <TableRow className={row.isError ? classes.errorRow : row.selected ? classes.selectedRow : ''} key={row.name + i}>
            <TableCell>{row.name}</TableCell>
            <TableCell >{row.ip}</TableCell>
            <TableCell colSpan={3}>Installing...</TableCell>
        </TableRow>
    )
}

function AccountInfoTable({ userDetails }) {

    const classes = useStyles()

    return (

        <Box pt={3}>
            <TableContainer >
                <Table size="small" aria-label="a dense table">

                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>Account Details</TableCell>
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow>
                            <TableCell>Username:</TableCell>
                            <TableCell>{userDetails.user_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Email:</TableCell>
                            <TableCell>{userDetails.user_detail.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Discord ID:</TableCell>
                            <TableCell>{userDetails.user_detail.discord}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function ServerInfoTable({ serverInfo }) {

    const classes = useStyles()
    console.debug(serverInfo)

    return (

        <Box pt={1}>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>Server Name</TableCell>
                            <TableCell >Players</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow>
                            <TableCell>{serverInfo.ServerName}</TableCell>
                            <TableCell>{serverInfo.PlayerCount}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function MapRotationTable({ mapRotation, currentMap, currentGameMode }) {

    const classes = useStyles()
    // console.log('MapRotation:',mapRotation, currentMap)

    return (
        <Box pt={1}>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>Server Maps</TableCell>
                            <TableCell>GameMode</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {mapRotation.map((map,i)=>
                        <TableRow className={(currentMap == map.MapName && currentGameMode == map.GameMode) ? classes.currentMapRow : ''} key={`${map.MapName}${i}`}>
                            <TableCell>{map.MapName}</TableCell>
                            <TableCell>{map.GameMode}</TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function ServerModsTable({ serverMods }) {

    const classes = useStyles()
    // console.log('MapRotation:',mapRotation, currentMap)

    return (
        <Box pt={1}>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>Server Mods</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {serverMods.map((mod, i)=>
                        <TableRow key={`${mod}${i}`}>
                            <TableCell>{mod.ModName}</TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}



function TitleTable({ title }) {

    const classes = useStyles()
    return (
        <Box pt={3}>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>{title}</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </Box>
    );
}

function ServerSettingsTable({ data, saveChanges, isSaving }) {
    const classes = useStyles()
    const gameModes = require('../../data/commands.json').gamemodes
    const mapSelectors = require('../../data/filters.json').mapSelectors
    const mapSelectorDesc = mapSelectors.map(s => s.desc)
    const initSelector = mapSelectors.find(s => s.name == data.selector).desc
    const dataScoreBoardObj = data.scoreboard
    const defaultHomeTxt = `Manage your own server at PavlovHorde.com!`
    const defaultScoreboardId = `Greatest Khan`
    const defaultFromTime = `2021-09-01`

    const [myPass, setMyPass] = useState(data.Password)
    const [pError, setPError] = useState(false)
    const [myName, setMyName] = useState(data.ServerName)
    const [tError, setTError] = useState(false)
    const [myTime, setMyTime] = useState(data.TimeLimit)
    const [wError, setWError] = useState(false)

    const [myCompMode, setMyCompMode] = useState(data.bCompetitive ? JSON.parse(data.bCompetitive) : false)
    const [myWebhook, setMyWebook] = useState(data.webhook)
    const [mySelector, setMySelector] = useState(data.selector)
    const [mySelectorTxt, setMySelectorTxt] = useState(data.selectorTxt)
    const [myHomeMap, setMyHomeMap] = useState(data.myHomeMap)
    const [myScoreboard, setMyScoreboard] = useState(dataScoreBoardObj)
    const [myFromTime, setMyFromTime] = useState(dataScoreBoardObj && dataScoreBoardObj.fromTime || defaultFromTime)

    const [mapsList, setMapsList] = useState(['Loading...'])
    const [mapsListNames, setMapsListNames] = useState(['Loading...'])

    const [homeMapsList, setHomeMapsList] = useState(['Loading...'])
    const [scoreboardList, setScoreboardList] = useState(['Loading...'])
    const [myMaps, setMyMaps] = useState(data.Maps)
    const [isChanged, setIsChanged] = useState(false)
    const [isWebhookChanged, setIsWebhookChanged] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const mapsRequestFunc = api.getMapsModio()
            const modsRequestFunc = api.getModsModio()
            const [mapsJSON, modsJSON] = await Promise.all([mapsRequestFunc, modsRequestFunc])
          // console.log(mapsJSON)
            const customMaps = mapsJSON.map(m => m.id)
            const customMapsNames = mapsJSON.map(m => m.name)

            // const scoreboardIds = homeScoreboards.map(s=>s.name)
            const allMaps = customMaps
            setMapsListNames(customMapsNames)
            setMapsList(allMaps)
        }
        fetchData();
    }, []);

    useEffect(() => {
        setMyName(data.ServerName)
        setMyPass(data.Password || "")
        const myMapsData = addMapNames(data.Maps)
        setMyMaps(myMapsData)
        setMyCompMode(data.bCompetitive ? JSON.parse(data.bCompetitive) : false)
    }, [data]);

    const addMapNames = (maps) => {
        return maps.map(m => {
            const mapIndex = mapsList.indexOf(m.MapId)
            const mapName = mapsListNames[mapIndex]
            return { ...m, name: mapName }
        })
    }

    const handleMapChange = (i, e) => {
        let newMaps = myMaps
        const mapIndex = mapsListNames.indexOf(e)
        const mapId = mapsList[mapIndex]
        newMaps[i].MapId = mapId
      // console.log(mapsListNames, mapsList, mapIndex, mapId, newMaps)
        setMyMaps(newMaps)
        setIsChanged(!isChanged)
    }

    const handleModeChange = (i, e) => {
        let newMaps = [...myMaps]
        newMaps[i].GameMode = e
        setMyMaps(newMaps)
        setIsChanged(!isChanged)
    }

    const handleRemove = (i) => {
        let newMaps = [...myMaps]
        newMaps.splice(i, 1)
        setMyMaps(newMaps)
        setIsChanged(!isChanged)
    }

    const handleAdd = () => {
        let newMaps = [...myMaps]
        newMaps.push({ 'MapId': 'sand', 'GameMode': 'SND' })
        setMyMaps(newMaps)
        setIsChanged(!isChanged)
    }

    const handleNameChange = (e) => {
        setMyName(e.target.value)
    }

    const handlePassChange = (e) => {
        const newPass = e.target.value
        const regex = /^\d+$/
        if (regex.test(newPass) || !newPass) {
            setPError(false)
        } else {
            setPError(true)
        }
        setMyPass(newPass)
    }
    const handleTimeChange = (e) => {
        const newTime = e.target.value
        const regex = /^\d+$/
        if (regex.test(newTime) || !newTime) {
            setTError(false)
        } else {
            setTError(true)
        }
        setMyTime(newTime)
    }

    const handleWebhookChange = (e) => {
        const newWebhook = e.target.value
        const regex = /https:\/\/discord\.com\/api\/webhooks\/[^" ]*$/
        if (regex.test(newWebhook) || !newWebhook) {
            setWError(false)
            setIsWebhookChanged(true)
        } else {
            setWError(true)
            setIsWebhookChanged(false)
        }
        setMyWebook(newWebhook)
    }

    const handleSelectorChange = (mode) => {

        const modeI = mapSelectorDesc.indexOf(mode)
        const modeName = mapSelectors[modeI].name
        setMySelector(modeName)
        // console.log('Selected',modeName)
    }

    const handleSelectorTxtChange = (e) => {
        const txt = e.target.value
        if (txt.length > 1000) {
            setTError(true)
        } else {
            setTError(false)
        }
        setMySelectorTxt(txt)
    }

    const handleHomeChange = (e) => {
        const selectedHomeMap = e
        // console.log(selectedHomeMap)
        setMyHomeMap(selectedHomeMap)
    }

    const handleScoreboardChange = (scoreboardname) => {
        // console.log(e)
        // const selectedScoreboard = e.target.value
        // console.log(selectedScoreboard)
        const scoreboardObj = scoreboardList.find(s => s.name == scoreboardname)
        setMyScoreboard({ ...scoreboardObj, fromTime: myFromTime })
    }

    const handlefromTimeChange = (e) => {
        const selectedDate = e.target.value
        // console.log('Selected: ',selectedDate)
        // const selectedScoreboard = e.target.value
        // console.log(selectedScoreboard)
        setMyScoreboard({ ...myScoreboard, fromTime: selectedDate })
        setMyFromTime(selectedDate)
    }

    const handleSave = () => {
      // console.log(myMaps)
        const newMaps = myMaps.map(m => ({ MapId: m.MapId.replaceAll('"', ''), GameMode: m.GameMode.replaceAll('"', '') }))
        let newSettings = { ...data, 'ServerName': myName, 'Maps': newMaps, 'Password': myPass, 'TimeLimit': myTime, selector: mySelector, selectorTxt: mySelectorTxt, myHomeMap, scoreboard: myScoreboard, webhook: myWebhook, bCompetitive: myCompMode.toString() }
        let newWebhook
        if (isWebhookChanged) {
            newWebhook = myWebhook
            newSettings.webhook = newWebhook
        }
      // console.log('Sending:', newSettings)
        // saveChanges(newSettings, newWebhook)
    }

    const handleCompMode = () => {
        setMyCompMode(!myCompMode)
    }

    return (
        <Box>
            <Box>
                <TableContainer >
                    <Table className={classes.table} size="small" aria-label="a dense table">

                        <TableHead>
                            <TableRow className={classes.headerRow}>
                                <TableCell>Server Settings</TableCell>
                                <TableCell ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow >
                                <TableCell>Name<HordeTip text={`The name of the server as it appears on the game server list`} /></TableCell>
                                <TableCell>
                                    <TextField onChange={handleNameChange} value={myName}></TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>PIN<HordeTip text={`Set a numeric PIN here to lock the server, allowing in only those who can enter this PIN.`} /></TableCell>
                                <TableCell>
                                    <TextField label={pError && "Error! Numbers Only"} error={pError} onChange={handlePassChange} value={myPass}></TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Game Time (mins)<HordeTip text={`How many minutes will each game last?`} /></TableCell>
                                <TableCell>
                                    <TextField label={tError && "Error! Numbers Only"} error={tError} onChange={handleTimeChange} value={myTime}></TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Discord Webhook<HordeTip text={`To stream server activity (active players and KDA stats) to a Discord Channel, go to the channel settings in discord and create a webhook. Copy the Webhook URL and paste here, then save changes.`} /></TableCell>
                                <TableCell>
                                    <TextField label={wError && "Error! Must be valid Discord Webhook URL"} error={wError} onChange={handleWebhookChange} value={myWebhook}></TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Competitive Mode<HordeTip text={`When enabled this puts dead players in a separate room to prevent them viewing the game and ghosting`} /></TableCell>
                                <TableCell>
                                    <Checkbox checked={myCompMode} onChange={handleCompMode}></Checkbox>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* {isPCServer ? 
                    <MapSelectTablePC {...{ classes, gameModes, handleAdd, handleMapChange, handleModeChange, handleRemove, myMaps }} /> : */}
                <MapSelectTable {...{ classes, gameModes, handleAdd, handleMapChange, handleModeChange, handleRemove, myMaps, mapsList, mapsListNames }} />
                {/* } */}
            </Box>
            <Box>
                {isSaving ? <CircularProgress /> :
                    <Button size="large" color="secondary" variant="contained" onClick={() => handleSave()}>Save Changes</Button>
                }
            </Box>
        </Box>
    );
}

export { AccountInfoTable, ServerTableShack, ServerTablePC, PlayersTable, ServerSettingsTable, ServerInfoTable, TitleTable, MapRotationTable, ServerModsTable }
