import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 500,
    fontSize: '0.5rem'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  compact:{
    padding: '0',
    margin:'0',
    fontSize: '1rem'
  },
  longer:{
    minHeight:170
  },
  select:{
    maxWidth:'12rem'
  }
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MultipleSelect({ title, data, setValue, defaultValue}) {
  const classes = useStyles();
  const theme = useTheme();

  const [visibleValue, setVisibleValue] = useState('')

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setValue(value)
  };

  return (
    <div>
      <InputLabel shrink htmlFor="select-multiple-native">
        {title}
      </InputLabel>
      <Select
        classes={{"root":classes.longer}}
        multiple
        native
        value={defaultValue}
        onChange={handleChangeMultiple}
        inputProps={{
          id: 'select-multiple-native',
        }}
      >
        {data.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
    </div>
  )
}

function SingleSelect({ title, data, setValue, value}) {
  
  const classes = useStyles()

  const handleChange = (event) => {
      setValue(event.target.value)
  };

  return (
    <div className={classes.select}>
      {title && 
      <InputLabel shrink htmlFor="select-multiple-native">
        {title}
      </InputLabel>
}
      <Select
        native
        value={value}
        onChange={handleChange}
        inputProps={{
          id: `select-native ${title}`,
        }}
      >
        {data && data.map((x,i) => (
          <option key={'' + x + i} value={x}>
            {x}
          </option>
        ))}
      </Select>
    </div>
  )
}


function CommandRadio({ data, setCommand }) {
  const classes = useStyles();

  const [value, setValue] = React.useState(data[0]);

  const handleChange = (event) => {
    setValue(event.target.value)
    setCommand(event.target.value)
  };

  return (
    <div >
      <RadioGroup aria-label="gender" name="commands" value={value} onChange={handleChange}>
        {data.map((name, i) => (
          <FormControlLabel key={name+(2*i)} classes={{"label":classes.compact}} value={name} control={<Radio key={name+i} classes={{"root":classes.compact}} size="small"/>} label={name} />
        ))}
      </RadioGroup>
    </div>)
}

export {MultipleSelect, CommandRadio, SingleSelect}