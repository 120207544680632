import { TableSortLabel, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { useStyles } from './table';


export function ModioTable({ data, setData }) {

    const classes = useStyles();

    const [sortBy, setSortBy] = useState('mdateStr');
    const [sortDirection, setSortDirection] = useState('desc');

    const headers = [
        {
            nameTxt: 'Name',
            keyTxt: 'name',
            sort: 'al',
            type: 'link'
        },
        {
            nameTxt: 'ID',
            keyTxt: 'id',
            sort: 'al'
        },
        {
            nameTxt: 'Image',
            keyTxt: 'logo',
            type: 'img'
        },
        {
            nameTxt: 'Downloads',
            keyTxt: 'downloads',
            sort: 'num',
            sortKey: 'downloads'
        }
    ];

    const handleSortClick = (columnKey) => {
        // console.log(columnKey, sortBy)
        const thisHeader = headers.find(h => h.keyTxt == columnKey);
        const sortType = thisHeader.sort;
        const sortKey = thisHeader.sortKey || thisHeader.keyTxt;

        let newSortDirection = 'asc';
        if (sortBy == columnKey) {
            newSortDirection = sortDirection == 'asc' ? 'desc' : 'asc';
        }

        let newData = [];
        if (sortType == 'num') {
            newData = newSortDirection == 'desc' ?
                [...data].sort((a, b) => (b[sortKey] || 0) - (a[sortKey] || 0)) :
                [...data].sort((a, b) => (a[sortKey] || 0) - (b[sortKey] || 0));
        } else {
            newData = newSortDirection == 'desc' ?
                [...data].sort((a, b) => a[sortKey].localeCompare(b[sortKey])) :
                [...data].sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
        }
        setData(newData);
        setSortDirection(newSortDirection);
        setSortBy(columnKey);
    };

    return (
        <Box>
            <TableContainer className={classes.table2}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {headers.map(h => <TableCell padding="none" key={h.nameTxt}>

                                <TableSortLabel
                                    direction={sortDirection}
                                    onClick={() => handleSortClick(h.keyTxt)}
                                    active={sortBy == h.keyTxt}
                                >
                                    {h.nameTxt}
                                </TableSortLabel>

                            </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.smallerCellTxt}>
                        {data.map((row, i) => 
                        
                        <ModioRow row={row} i={i} headers={headers} />

                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

}

function ModioRow({row, i, headers}) {

    const classes = useStyles();

    const [showDesc, setShowDesc] = useState(false);

    const toggleShowDesc = () => {
        setShowDesc(!showDesc);
    }

    return <TableRow onClick={toggleShowDesc} key={`${row.id}${i}`}>
        {headers.map((head, j) => {

            const rowObjectKey = `${row[head.nameTxt]}${i}${row.id}${j}`

            if (head.type && head.type == 'img') {
                return (
                <TableCell key={rowObjectKey}>
                    <img src={row.logo} />
                    </TableCell>
                );
            }
            if (head.type && head.type == 'link') {
                return (
                    <TableCell key={rowObjectKey}>
                        <a className={classes.href} href={row.profile}> {row[head.keyTxt]} </a>
                    </TableCell>
                )

            }
            return <TableCell key={rowObjectKey}>{row[head.keyTxt]}</TableCell>;
        }
        )}
    </TableRow>;
}

